// extracted by mini-css-extract-plugin
export var background_images = "whoWorkDesktop-module__background_images___+z3HC";
export var body_background = "#f8f9fa";
export var card = "whoWorkDesktop-module__card___pxckp";
export var chip = "whoWorkDesktop-module__chip___UZi3f";
export var container = "whoWorkDesktop-module__container___-z3V5";
export var content = "whoWorkDesktop-module__content___+JIFK";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "whoWorkDesktop-module__full_img___pglG1";
export var full_imgSmall = "whoWorkDesktop-module__full_img--small___9u-93";
export var gray_21 = "whoWorkDesktop-module__gray_21___YVrRt";
export var image_container = "whoWorkDesktop-module__image_container___6K6B8";
export var image_item = "whoWorkDesktop-module__image_item___BE6eC";
export var lg = "1200px";
export var logo = "whoWorkDesktop-module__logo___ktaAK";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "whoWorkDesktop-module__primary___t39va";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var timeline = "whoWorkDesktop-module__timeline___1Ql2S";
export var timeline__img = "whoWorkDesktop-module__timeline__img___qD1hZ";
export var timeline__step = "whoWorkDesktop-module__timeline__step___Xah+V";
export var timeline__subtitle = "whoWorkDesktop-module__timeline__subtitle___FnyJY";
export var timeline__text = "whoWorkDesktop-module__timeline__text___rKp8Y";
export var timeline__timeline_dot = "whoWorkDesktop-module__timeline__timeline_dot___wMI-S";
export var timeline__timeline_item = "whoWorkDesktop-module__timeline__timeline_item___QPvJL";
export var timeline__timeline_item__card = "whoWorkDesktop-module__timeline__timeline_item__card___2FfzA";
export var timeline__timeline_item__timeline_content = "whoWorkDesktop-module__timeline__timeline_item__timeline_content___H8OmW";
export var timeline__title = "whoWorkDesktop-module__timeline__title___ydTOG";
export var title = "whoWorkDesktop-module__title___T9mBI";
export var white = "#fff";
export var white_button = "whoWorkDesktop-module__white_button___T8idM";
export var xl = "1536px";
export var xxl = "2500px";