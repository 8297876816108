import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as styles from './whoWorkDesktop.module.scss';

const WhoWorkDesktop = ({ intl: { formatMessage } }) => {
  return (
    <section>
      <div className={styles.timeline}>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>1.</p>
            <StaticImage
              src="../../../../images/why_work_read.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep1Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep1Content' })}
            </small>
          </div>
          <div className={styles.timeline__timeline_dot}></div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep2Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep2Content' })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <StaticImage
              src="../../../../images/why_work_group.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
            <p className={styles.timeline__step}>2.</p>
          </div>
          <div className={styles.timeline__timeline_dot}></div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>3.</p>
            <StaticImage
              src="../../../../images/why_work_ai.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep3Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep3Content' })}
            </small>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep4Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep4Content' })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <StaticImage
              src="../../../../images/why_work_desk.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
            <p className={styles.timeline__step}>4.</p>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>5.</p>
            <StaticImage
              src="../../../../images/why_work_document.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep5Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep5Content' })}
            </small>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: 'businessWhoWorkStep6Title' })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: 'businessWhoWorkStep6Content' })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <StaticImage
              src="../../../../images/why_work_skills.webp"
              className={styles.timeline__img}
              objectFit="cover"
              alt="desk"
              loading="lazy"
              placeholder="blurred"
            />
            <p className={styles.timeline__step}>6.</p>
          </div>
        </div>
      </div>
    </section>
  );
};
WhoWorkDesktop.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(WhoWorkDesktop);
